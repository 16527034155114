import React, { useEffect, useState } from 'react';
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { quantityDecrementDenomination, quantityIncrementDenomination } from '../../../store/productDetails/action';

const QuantityHandle = ({ denominationData, rangeDenomination }) => {
    const [selectedUser, setSelectedUser] = useState(false);
    const [quantity, setQuantity] = useState(0);
    const dispatch = useDispatch();
    const USER_PROFILE = useSelector((state) => state.UserProfile?.allData);

    useEffect(() => {
        if (rangeDenomination) {
            setQuantity(1);
        }

        if (USER_PROFILE?.['data']?.['id'] == 70053) {
            setSelectedUser(true);
        } else {
            setSelectedUser(false);
        }
    }, [rangeDenomination, USER_PROFILE]);

    useEffect(() => {
        dispatch(quantityIncrementDenomination(denominationData.AMOUNT, quantity));
    }, [dispatch, denominationData.AMOUNT, quantity])

    const handleIncrement = () => {
        if (quantity >= 0 && quantity < (selectedUser ? 100000000 : 5)) {
            setQuantity((prevState) => prevState + 1);

        }
    };

    const handleDecrement = () => {
        if (quantity > 0 && quantity <= (selectedUser ? 100000000 : 5)) {
            setQuantity((prevState) => prevState - 1);
            // dispatch(quantityDecrementDenomination(denominationData.AMOUNT, quantity - 1));
        }
    };

    const handleChange = (e) => {
        const value = e.target.value;
        if (!isNaN(value) && value >= 0 && value <= 100000000) {
            setQuantity(Number(value));
        }
    };

    return (
        <React.Fragment>
            <RemoveRoundedIcon
                className='product__denomination_quantity_icon'
                onClick={handleDecrement}
                sx={{ width: { xs: 16, sm: '16px' } }}
            />

            {selectedUser ? (
                <TextField
                    type="text"
                    value={quantity}
                    onChange={handleChange}
                    margin="normal"
                    size="small"
                    name="Denomination Amount"
                    autoComplete="off"
                    InputProps={{
                        style: {
                            fontSize: '15.5px',
                            letterSpacing: '0.2em',
                        },
                    }}
                    sx={{
                        py: "4px"
                    }}
                />
            ) : (
                <Typography sx={{
                    maxWidth: "40px",
                    fontSize: { xs: "11.5px", sm: ".9rem", lg: "1rem" },
                    color: "textPrimary.main1",
                    fontWeight: { xs: 500, }
                }}>
                    {quantity}
                </Typography>
            )}

            <AddRoundedIcon
                className='product__denomination_quantity_icon'
                onClick={handleIncrement}
                sx={{ width: { xs: 16, sm: '16px' } }}
            />
        </React.Fragment>
    );
};

export default QuantityHandle;