import WalletPaymentConfirm from '../../../../components/alertPopup/WalletPaymentConfirm';
import { Box, Checkbox, List, ListItem, Typography } from "../../../../imports/muiImport";
import { useState, useSelector } from "../../../../imports/generalImport";
import { useEffect } from 'react';
import { handleAmount } from '../../../../shared/extra';

function PayFromWallet({ walletPayment, setWalletPayment }) {
    const [netPay, setNetPay] = useState(0);
    const [walletPayConfirm, setWalletPayConfirm] = useState(false);
    let userProfile = useSelector((state) => state.UserProfile.allData?.data);
    const DENOMINATION_STORE_DATA = useSelector((state) => state.Denominations?.allData);
    const calculateNetPay = (items) => items.reduce((acc, item) => acc + item.TOTAL, 0);
    const TOTAL_DISCOUNT = (total, dis) => total / 100 * dis;
    const balance = useSelector((state) => state.UserBalance.allData);

    useEffect(() => {
        if (DENOMINATION_STORE_DATA?.length > 0) {
            const total = calculateNetPay(DENOMINATION_STORE_DATA);
            const finalPay = TOTAL_DISCOUNT(total, DENOMINATION_STORE_DATA?.[0]?.DISCOUNT);
            const np = total - finalPay;
            const bal = balance;
            if (bal >= np) {
                setNetPay(np)
            } else {
                setNetPay(bal);
            }
        } else {
            setNetPay(0);
        }
    }, [DENOMINATION_STORE_DATA]);

    const handleWalletPayConfirmOpen = () => {
        setWalletPayConfirm(true);
    };

    const handleWalletPayConfirmClose = () => {
        setWalletPayConfirm(false);
    };

    return (
        <>
            <Box sx={{ mt: { xs: 0, lg: 2 } }}>
                <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                        color: {
                            xs: "primary.main",
                            sm: "textPrimary.main"
                        },
                        fontSize: {
                            xs: "1.2rem",
                            sm: "13.5px",
                            lg: "15px"
                        },
                        fontWeight: {
                            xs: 400,
                            sm: 500,
                            lg: 400
                        },
                        textAlign: { xs: "center", lg: "left" },
                        mb: { xs: 1, lg: 0 },
                        borderBottom: { xs: 1, lg: 0 },
                        mt: { xs: 1, sm: 1, lg: 0 }
                    }}
                >
                    Pay with Wallet
                </Typography>
                <Box sx={{ borderRadius: 2, minHeight: "30px", p: "10px 8px", backgroundColor: "#0028650a" }}>
                    <List sx={{ py: 0 }}>
                        <ListItem sx={{ justifyContent: "space-between" }}>
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                                <Typography variant="subtitle1"
                                    sx={{
                                        color: "textPrimary.main",
                                        fontWeight: 400,
                                        fontSize: {
                                            xs: ".9rem",
                                            sm: "13.5px"
                                        },
                                        py: 0
                                    }}>
                                    Available Balance :
                                </Typography>
                                <Typography variant="subtitle1"
                                    sx={{
                                        color: "textPrimary.main",
                                        fontWeight: 400,
                                        fontSize: {
                                            xs: "1rem",
                                            sm: "13.5px"
                                        },
                                        py: 0,
                                        ml: 2
                                    }}>
                                    &#8377;{balance >= 1 ? handleAmount(balance) : "0.00"}
                                </Typography>
                            </Box>

                            <Checkbox
                                inputProps={{ 'aria-label': 'Pay from wallet' }}
                                checked={walletPayment}
                                onClick={handleWalletPayConfirmOpen}
                                disabled={!netPay || userProfile?.['balance'] <= 1}
                            />
                        </ListItem>

                    </List>

                </Box>
            </Box>

            <WalletPaymentConfirm
                walletPayConfirmTitle={'Pay from Your Wallet'}
                walletPayConfirmContent={walletPaymentConfirmation(userProfile, netPay)}
                open={walletPayConfirm}
                handleWalletPayConfirmOpen={handleWalletPayConfirmOpen}
                handleWalletPayConfirmClose={handleWalletPayConfirmClose}
                setWalletPayment={setWalletPayment}
            />
        </>
    );


    function walletPaymentConfirmation(userProfile, totalPayout) {
        return (<Typography
            variant="subtitle1"
            sx={{
                color: "dark.main2",
                fontSize: "18px",
                textTransform: "capitalize",
                fontWeight: 400,
                textAlign: "center"
            }}>
            Are you sure you want to deduct
            <Typography component="span" sx={{ color: "dark.main", fontWeight: 500, fontSize: "18px", }}>
                ₹{
                    totalPayout > userProfile?.['balance'] ?
                        userProfile?.['balance']
                        :
                        totalPayout
                }
            </Typography>
            amount from your wallet ?
        </Typography>);
    }

}

export default PayFromWallet;