import React, { useMemo } from "react";
import Header from "./Header";
import FooterReserved from "./innerComponent/FooterReserved";
import { Container, CssBaseline } from "@mui/material";
import { Outlet } from "react-router-dom";
import "./innerComponent/styles/navbarmenu.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LargeFooter from "./ProfileLayout/FOOTER/LargeFooter";
import ScrollToTop from "../components/other/ScrollToTop";

const Layout = ({ children }) => {
  const memoizedHeader = useMemo(() => <Header />, []);
  const memoizedFooter = useMemo(() => <LargeFooter />, []);
  const memoizedFooterReserved = useMemo(() => <FooterReserved />, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <ScrollToTop />
      <Container
        maxWidth="false"
        disableGutters={true}
        sx={{
          maxWidth: "100%",
          alignItem: "flex-start",
          border: 0,
          backgroundColor: {
            xs: "white.main",
            sm: "backgroundPrimary.main",
          },
        }}
      >
        {memoizedHeader}
        <Container
          maxWidth="xl"
          sx={{
            minHeight: "40vh",
            backgroundColor: {
              xs: "white.main",
              sm: "backgroundPrimary.main",
            },
            alignItem: "center",
            position: "relative",

          }}
          disableGutters={true}
        >

          <Outlet>
            {children}
          </Outlet>

        </Container>
        {memoizedFooter}
        {memoizedFooterReserved}
      </Container>
    </React.Fragment>
  );
};

export default React.memo(Layout);
