import React, { useState, useEffect, useRef } from "react";
import { Box, Divider } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import SearchResult from "./LARGENAVBAR/SearchResult";
import './styles/navbar-responsive.css';
import NavbarCategory from "./NavarCategories";
import { getAllProducts } from "../../apiCalls/urls/urlFunction";
import { useDebounce } from './../../hooks/useDebounce';
import { useLocation } from "react-router-dom";

function NavbarSearch() {
    const [loading, setLoading] = useState(true)
    const [inputValue, setInputValue] = useState('');
    const [searchResultData, setSearchResultData] = useState([]);
    const [showProductList, setShowProductList] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState();
    const [resultFound, setResultFound] = useState(true);
    const searchDebounce = useDebounce(inputValue);
    const resultBoxRef = useRef(null);
    const inputRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        if (inputValue.trim().length > 1) {
            fetchUseProducts(searchDebounce, selectedCategory);
        }
    }, [searchDebounce, selectedCategory]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                resultBoxRef.current &&
                !resultBoxRef.current.contains(event.target) &&
                !inputRef.current.contains(event.target)
            ) {
                if (showProductList) {
                    setShowProductList(false);
                }
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showProductList]);

    const handleFocus = () => {
        if (inputValue.trim().length > 1) {
            if (!showProductList) {
                setShowProductList(true);
            }
        }
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleClearInput = () => {
        setInputValue('');
        setShowProductList(false);
    };

    useEffect(() => {
        handleClearInput();
    }, [location.pathname, location.search]);



    return (
        <React.Fragment>
            <NavbarCategory setSelectedCategory={setSelectedCategory} />
            <Divider orientation="vertical" variant="middle" flexItem />
            <Box sx={{
                position: "relative",
                width: "100%",
                "& .MuiFormLabel-root": {
                    textAlign: 'center',
                    fontSize: "12px",
                    color: "primary.main"
                }
            }}>
                <Box component="div" sx={{ position: 'relative' }}>
                    <Box
                        component="input"
                        id="myInput"
                        type="text"
                        value={inputValue}
                        ref={inputRef}
                        onFocus={handleFocus}
                        onChange={handleInputChange}
                        placeholder="Search Brand, Products..."
                        autoComplete="off"
                        sx={{
                            width: "100%",
                            maxWidth: "100%",
                            border: "none",
                            backgroundColor: "transparent",
                            fontSize: {
                                sm: "10px",
                                md: "13px",
                                xl: "15px"
                            },
                            textIndent: {
                                sm: "15px",
                                xl: "15px"
                            },
                            fontWeight: {
                                sm: 500
                            },
                            fontFamily: "typography.main",
                            color: "primary.main !important",
                            padding: "10px 0",
                            outline: "none",
                        }}
                    />
                    {inputValue ?
                        <IconButton
                            size="medium"
                            style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}
                            onClick={handleClearInput}
                        >
                            <ClearIcon />
                        </IconButton>
                        :
                        <IconButton
                            size="medium"
                            style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}
                        >
                            <SearchIcon />
                        </IconButton>
                    }
                </Box>

                <Box sx={{ position: "static" }} >
                    <SearchResult
                        loading={loading}
                        showProductList={showProductList}
                        resultBoxRef={resultBoxRef}
                        searchResultData={searchResultData}
                        resultNotFound={resultFound} />
                </Box>
            </Box>
        </React.Fragment>
    );

    async function fetchUseProducts(search, category) {
        if (search) {
            setLoading(true)
            setShowProductList(true); // Show the search result box when fetching products

            let response = await getAllProducts({
                search: search,
                filterBy: "title",
                selectedCategories: category ? [category] : [],
                pagination: {
                    sortBy: "id",
                    descending: false,
                    page: 1,
                    rowsPerPage: 15,
                    rowsNumber: 0,
                },
            });

            if (response.status) {
                setLoading(false)
                setSearchResultData(response?.data?.data);
                setResultFound(response?.data?.pagination?.total === 0)
            } else {
                console.error(response.error);
            }
        }
    }
}

export default NavbarSearch;
