import React, { useEffect, useState, Fragment, useRef } from "react";
import {
    Typography, Box, Divider, List, ListItemButton, IconButton,
} from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import { onFetchCategory, onLoad } from "./action";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearIcon from "@mui/icons-material/Clear";

const NavbarCategory = (props) => {
    const AllCategory = useSelector((state) => state.Category.allData);
    const [isOpen, setIsOpen] = useState(false);
    const [selected, setSelected] = useState();
    const dropdownRef = useRef(null); // Create a reference for the dropdown menu

    // Add a click event listener to the document
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => setIsOpen(!isOpen);
    const handleSelect = (option) => {
        setSelected(option?.title);
        setIsOpen(false);
        props?.setSelectedCategory(option?.id);
    };

    const handleSelected = () => {
        setSelected('');
        props?.setSelectedCategory();
    };

    return (
        <Box sx={{
            width: {
                sm: "160px",
                xl: "235px"
            },
            display: "flex",
            justifyContent: "center",
            textAlign: "center"
        }}>
            <Box sx={{
                position: "relative",
                width: "100%"
            }}>
                <Box
                    component="div"
                    style={{
                        width: "100%",
                        maxWidth: "100%",
                        border: "none",
                        backgroundColor: "transparent",
                        padding: "10px 0",
                        outline: "none",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center"
                    }}>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            fontSize: {
                                sm: "10px",
                                md: "12px",
                                xl: "14px"
                            },
                            color: "primary.main",
                            lineHeight: "15px",
                            fontWeight: {
                                sm: 500,
                                xl: 500
                            },
                            width: "100%",
                        }}
                    >
                        {selected || "All Categories"}
                    </Typography>

                    <Box sx={{ width: "30%" }}>
                        {selected &&
                            <IconButton
                                size="small"
                                style={{ position: 'absolute', top: '50%', right: '20px', transform: 'translateY(-50%)' }}
                                onClick={handleSelected}
                            ><ClearIcon />
                            </IconButton>
                        }

                        <IconButton
                            size="small"
                            style={{ position: 'absolute', top: '50%', right: '0', transform: 'translateY(-50%)' }}
                            onClick={toggleDropdown}
                        ><ArrowDropDownIcon />
                        </IconButton>
                    </Box>
                </Box>

                {isOpen && (
                    <Box
                        ref={dropdownRef} // Assign the reference to the dropdown menu
                        sx={{
                            position: "absolute",
                            top: {
                                xs: "30px",
                                xl: "36px"
                            },
                            left: {
                                xs: 0,
                                xl: "0px"
                            },
                            minWidth: "100%",
                            maxHeight: {
                                sm: "250px",
                                xl: "280px"
                            },
                            boxShadow: {
                                xl: 3
                            },
                            zIndex: 99,
                            backgroundColor: {
                                sm: "white.main"
                            },
                            borderRadius: {
                                xl: 1
                            },
                            overflow: 'auto',
                            '::-webkit-scrollbar': {
                                display: 'none',
                            },
                            '-ms-overflow-style': 'none',  // IE and Edge
                            'scrollbar-width': 'none',  // Firefox
                        }}
                    >
                        <List sx={{ pt: 0 }}>
                            {AllCategory?.map((option) => (
                                option?.products?.length > 0 &&
                                <Fragment key={option?.id}>
                                    <ListItemButton onClick={() => handleSelect(option)}>
                                        <Typography
                                            variant="subtitle1"
                                            sx={{
                                                fontSize: {
                                                    sm: "10px",
                                                    md: "12px",
                                                    lg: "13px"
                                                },
                                                lineHeight: 1,
                                                fontWeight: 500,
                                                py: {
                                                    xl: 1
                                                },
                                                color: "primary.main",
                                            }}
                                        >
                                            {option?.mobile_title}
                                        </Typography>
                                    </ListItemButton>
                                    <Divider />
                                </Fragment>
                            ))}
                        </List>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default NavbarCategory

