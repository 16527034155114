import { AppBar, Badge, Box, IconButton, Stack, Toolbar, Typography } from "../../../imports/muiImport";
import { React, useEffect, useState, useSelector, useDispatch } from "../../../imports/generalImport";

import MenuIcon from '@mui/icons-material/Menu';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/contexts/AuthContext';
import CircularProgress from '@mui/material/CircularProgress';
import { handleAmount } from '../../../shared/extra';
import { fetchUserBalance } from "../../../store/userBalance/action";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

function MobileNavbar({ toggleDrawer, isPageTitleUpdate, pageTitle }) {
    const [canGoBack, setCanGoBack] = useState(false);
    const UserInfomation = useSelector((state) => state.UserProfile?.allData);
    const balance = useSelector((state) => state.UserBalance.allData);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { authUser, setAuthUser, isLoggedIn, setIsLoggedIn } = useAuth();

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(fetchUserBalance());
        }
    }, [isLoggedIn]);

    useEffect(() => {
        // Check if the user has browser history
        if (window.history.length > 1) {
            setCanGoBack(true);
        }
    }, []);


    const handleBackClick = () => {
        if (canGoBack) {
            navigate(-1); // Navigate back if there's history
        } else {
            navigate('/'); // Redirect to home if there's no history
        }
    };


    function capitalize(word) {
        return word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase();
    }

    return (
        <Box sx={{ flexGrow: 1, backgroundColor: "white.main" }}>
            <AppBar position="fixed" sx={{
                top: "0 !important",
                backgroundColor: "white.main",
                boxShadow: 0,
                borderColor: "#686D76",
            }}>
                <Toolbar>

                    {isPageTitleUpdate ?
                        (
                            <IconButton
                                size="large"
                                edge="start"
                                aria-label="menu"
                                sx={{ color: "primaryDark.main" }}
                                onClick={handleBackClick}
                            >
                                <ArrowBackIosIcon sx={{ fontSize: 22 }} />
                            </IconButton>
                        )
                        :
                        (
                            <IconButton
                                size="large"
                                edge="start"
                                aria-label="menu"
                                sx={{ color: "primaryDark.main" }}
                                onClick={toggleDrawer(true)}
                            >
                                <MenuIcon sx={{ fontSize: 22 }} />
                            </IconButton>
                        )
                    }



                    {isPageTitleUpdate ?
                        <Stack sx={{ flexGrow: 1 }}>
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{
                                    flexGrow: 1,
                                    color: "primaryDark.main",
                                    textTransform: "capitalize"
                                }}
                            >
                                {capitalize(pageTitle)}
                            </Typography>
                        </Stack>
                        :
                        (
                            isLoggedIn ? (
                                UserInfomation && UserInfomation.data ? (
                                    <Stack sx={{ flexGrow: 1 }}>
                                        <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: "primaryDark.main" }}>
                                            {UserInfomation.data.name}
                                        </Typography>

                                        <Typography variant="inherit" component="div" sx={{
                                            flexGrow: 1,
                                            color: "primaryDark.main",
                                            fontSize: "10.5px"
                                        }}>
                                            Wallet Balance: ₹{balance > 1 ? handleAmount(balance) : "0.00"}

                                        </Typography>
                                    </Stack>
                                ) : (
                                    <Stack sx={{ flexGrow: 1 }}>
                                        <CircularProgress sx={{ color: "primary.main" }} size={15} />
                                    </Stack>
                                )
                            ) : (
                                <Stack sx={{ flexGrow: 1 }}>
                                    <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: "primaryDark.main" }}>
                                        Guest
                                    </Typography>
                                </Stack>
                            )
                        )
                    }

                    {/* {isLoggedIn &&
                            (<Typography variant="inherit" component="div" sx={{
                                flexGrow: 1,
                                color: "primaryDark.main",
                                fontSize: "10.5px"
                            }}>
                                Wallet Balance : ₹{isLoggedIn ? UserInfomation?.data?.balance : "1000.00"}
                            </Typography>)
                        } */}


                    {(isLoggedIn && !isPageTitleUpdate) && (
                        <div>
                            {/* <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                // onClick={handleMenu}
                                color="inherit"
                            >
                                <DiscountIcon sx={{ fontSize: 26, color: "primaryDark.main" }} />
                            </IconButton> */}

                            {/* <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                            >
                                <NavLink to={'/profile/notifications'} >
                                    <Badge variant="dot" color="error" invisible={!favourite}>
                                        <FavoriteIcon sx={{ fontSize: 26, color: "primaryDark.main" }} />
                                    </Badge>
                                </NavLink>
                            </IconButton> */}


                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                            >
                                <NavLink to={'/profile/notifications'} >
                                    <CircleNotificationsIcon sx={{ fontSize: 26, color: "primaryDark.main" }} />
                                </NavLink>
                            </IconButton>

                        </div>
                    )}

                </Toolbar>
            </AppBar>
        </Box >
    );
}

export default MobileNavbar