import React from 'react'
import { Box, Grid, Typography, List, ListItem, Divider } from "../../../../imports/muiImport";
import { useSelector } from "../../../../imports/generalImport";

export default function UserDetailPayment() {
    const userProfile = useSelector((state) => state.UserProfile.allData?.data);

    return (
        <Grid container>
            < Grid item xs={12} sx={{
                pl: {
                    lg: 1
                }
            }}>
                <Box
                    sx={{
                        mt: {
                            sm: 3,
                            md: 0,
                            lg: 0
                        },
                    }}
                >
                    <Typography
                        variant="body1"
                        gutterBottom
                        sx={{
                            color: {
                                xs: "primary.main",
                                sm: "textPrimary.main"
                            },
                            fontSize: {
                                xs: "1.2rem",
                                sm: "13.5px",
                                lg: "15px",
                            },
                            fontWeight: {
                                xs: 400,
                                sm: 500,
                                lg: 400
                            },
                            textAlign: { xs: "center", lg: "left" },
                            mb: { xs: 1, lg: 0 },
                            borderBottom: { xs: 1, lg: 0 },
                            mt: { xs: 1, sm: 1, lg: 0 }
                        }}
                    >
                        Receiver Details
                    </Typography>
                    <Box
                        sx={{
                            borderRadius: { xs: 1, lg: 2 },
                            backgroundColor: { xs: "backgroundPrimary.main", lg: "#0028650a" },
                            py: { xs: 0, lg: "10px" },
                            px: { xs: 0, lg: "8px" },
                            minHeight: { xs: "100px", lg: "204px", xl: "180px" }

                        }}
                    >

                        <List>
                            <ListItem >
                                <Typography variant="subtitle1" sx={{
                                    minWidth: {
                                        xs: "30%",
                                        sm: "65%",
                                        lg: "20%"
                                    },
                                    fontWeight: {
                                        xs: 400,
                                        lg: 300
                                    },
                                    fontSize: {
                                        xs: "1rem",
                                        sm: "13.5px"
                                    },
                                }}>Name</Typography>
                                <Typography variant="subtitle1" sx={{
                                    color: {
                                        xs: "textPrimary.main"
                                    },
                                    fontWeight: {
                                        xs: 400,
                                    },
                                    fontSize: {
                                        xs: ".8rem",
                                        sm: "13.5px"
                                    },
                                    minWidth: {
                                        xs: "70%",
                                        sm: "auto",
                                        lg: "80%"
                                    }
                                }}>{userProfile?.['name']}</Typography>
                            </ListItem>
                            <Divider component="li" />

                            <ListItem >
                                <Typography variant="subtitle1" sx={{
                                    minWidth: {
                                        xs: "30%",
                                        sm: "65%",
                                        lg: "20%"
                                    },
                                    fontWeight: {
                                        xs: 400,
                                        lg: 300
                                    },
                                    fontSize: {
                                        xs: "1rem",
                                        sm: "13.5px"
                                    },
                                }}>Phone</Typography>
                                <Typography variant="subtitle1" sx={{
                                    color: {
                                        xs: "textPrimary.main"
                                    },
                                    fontWeight: {
                                        xs: 400,
                                    },
                                    fontSize: {
                                        xs: ".8rem",
                                        sm: "13.5px"
                                    },
                                    minWidth: {
                                        xs: "70%",
                                        lg: "80%"
                                    }
                                }}>{userProfile?.['mobile']}</Typography>
                            </ListItem>
                            <Divider component="li" />

                            <ListItem >
                                <Typography variant="subtitle1" sx={{
                                    minWidth: {
                                        xs: "30%",
                                        sm: "65%",
                                        lg: "20%"
                                    },
                                    fontWeight: {
                                        xs: 400,
                                        lg: 300
                                    },
                                    fontSize: {
                                        xs: "1rem",
                                        sm: "13.5px"
                                    },
                                }}>Email</Typography>
                                <Typography variant="subtitle1" sx={{
                                    color: {
                                        xs: "textPrimary.main"
                                    },
                                    fontWeight: {
                                        xs: 400,
                                    },
                                    fontSize: {
                                        xs: ".8rem",
                                        sm: "13.5px"
                                    },
                                    minWidth: {
                                        xs: "70%",
                                        lg: "80%"
                                    },
                                    textTransform: "lowercase"
                                }}>{userProfile?.['email']}</Typography>
                            </ListItem>
                        </List>

                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}



