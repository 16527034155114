import { Box, Stack, Typography } from '@mui/material'
import React from 'react';
import PlaceIcon from "@mui/icons-material/Place";
import PhoneIcon from "@mui/icons-material/Phone";
import MarkunreadIcon from "@mui/icons-material/Markunread";

export default function AddressComponent() {
    return (
        <Box>

            <Typography
                variant="subtitle1"
                color="white.main"
                sx={{
                    textTransform: {
                        xs: "capitalize",
                    },
                    mb: { xs: "4px", md: 2 },
                    fontSize: { xs: "16px", sm: "16px", lg: "16px" },
                }}
            >
                Address
            </Typography>


            <Stack
                sx={{
                    width: {
                        xs: "100%",
                        sm: "100%",
                        md: "100%",
                        lg: "275px"
                    },
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                    alignItems: "center",
                    mb: 1,
                }}
            >
                <PlaceIcon sx={{ color: "white.main", fontSize: { xs: "14px", md: "20px" }, mr: { xs: "7px", md: "5px" } }} />
                <a href="https://maps.app.goo.gl/qiqGKES7NFd9DFv68" style={{ textDecoration: "none" }}>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            color: "white.main",
                            fontSize: { xs: "12px", md: "13px" },
                            lineHeight: { xs: "22px", md: "22px" },
                            width: { xs: "100%", md: "235px" },
                            textTransform: "capitalize",
                            wordSpacing: "1px",
                            fontWeight: 300,
                        }}
                    >
                        F-12 Agarsen Tower Central Spine, Vidhyadhar Nagar, Jaipur - 302039
                    </Typography>
                </a>
            </Stack>

            <Stack
                sx={{
                    width: {
                        xs: "100%",
                        sm: "275px"
                    },
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                    alignItems: "center",
                    mb: 1,
                }}
            >
                <PhoneIcon sx={{ color: "white.main", fontSize: { xs: "14px", md: "20px" }, mr: { xs: "7px", md: "5px" } }} />
                <a href="tel:+918742998998" style={{ textDecoration: "none" }}>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            color: "white.main",
                            fontSize: { xs: "12px", md: "13px" },
                            lineHeight: { xs: "15px", md: "15px" },
                            width: { xs: "100%", md: "235px" },
                            fontWeight: 300,
                            textTransform: "capitalize",
                            wordSpacing: "1px",
                        }}
                    >
                        +91 8742998998
                    </Typography>
                </a>
            </Stack>

            <Stack
                sx={{
                    width: {
                        xs: "100%",
                        sm: "275px"
                    },
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                    alignItems: "center",
                    mb: 1,
                }}
            >
                <MarkunreadIcon sx={{ color: "white.main", fontSize: { xs: "14px", md: "20px" }, mr: { xs: "7px", md: "5px" } }} />
                <a href="mailto:support@99gift.in" style={{ textDecoration: "none" }}>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            color: "white.main",
                            fontSize: { xs: "12px", md: "13px" },
                            lineHeight: { xs: "15px", md: "15px" },
                            width: { xs: "100%", md: "235px" },
                            fontWeight: { xs: 300, md: 300 },
                            textTransform: "lowercase",
                            wordSpacing: "1px",
                        }}
                    >
                        support@99gift.in
                    </Typography>
                </a>
            </Stack>

        </Box>
    )
}
