import * as React from "react";
import { AppBar, Box, Container, Toolbar } from "@mui/material";
import Logo from "../assets/img/logo.png";
import NavbarSearch from "./innerComponent/NavbarSearch";
import HandleUserLogin from "./HandleUserLogin";
import { NavLink } from "react-router-dom";

export default function Navbar() {
  return (
    <Container maxWidth="xl" disableGutters={true}>
      <AppBar
        position="static"
        disableElevation
        sx={{
          backgroundColor: "white.main",
          minHeight: "65px",
          position: 'static',
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          padding: {
            sm: "8px 0",
            lg: "5px 15px"
          },
          width: "100%",
          boxShadow: 1
        }}
      >
        <Toolbar
          disableElevation
          sx={{
            minWidth: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: {
              sm: 1,
              lg: 0,
              xl: 0
            }
          }}
        >

          <NavLink to="/" style={{ textDecoration: "none", }}>
            <Box component="img"
              sx={{
                height: { sm: "60px", md: "60px" },
                width: { sm: "120px", md: "140px" },
                objectFit: "cover",
                mr: { sm: 0, lg: "20px" },
                mixBlendMode: "multiply",
                cursor: "pointer"
              }}
              alt="Logo"
              src={Logo}
            />
          </NavLink>

          <Box sx={{
            flexGrow: 2,
            display: "flex",
            justifyContent: "center",
            mx: {
              sm: 3,
              lg: 4
            }

          }}>
            <Box
              sx={{
                display: { xs: "flex", md: "flex" },
                width: { sm: "100%", md: "100%", lg: "55vw", xl: "64vw" },
                maxWidth: { sm: "100%", md: "100%", lg: "802px", xl: "983px" },
                minHeight: {
                  sm: "45px",
                  lg: "49px"
                },
                alignItems: "center",
                border: {
                  sm: 1,
                  xl: 1
                },
                borderRadius: {
                  sm: 1,
                  xl: 1
                },
                borderColor: {
                  sm: "lightSecondary.main",
                  xl: "lightSecondary.main"
                },
                backgroundColor: {
                  // sm: "#f8f9fa",
                  sm: "white.main",
                  xl: "white.main"
                },
              }}>
              <NavbarSearch />
            </Box>
          </Box>

          <HandleUserLogin />

        </Toolbar>
      </AppBar>
    </Container >
  );
}
