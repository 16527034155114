import React from 'react';
import { Box } from "../../../imports/muiImport";

function MobileProductDetailImage({ image }) {
    return (
        <Box
            component={'img'}
            src={image}
            width={312}
            height={190}
            sx={{
                maxWidth: "75vw",
                boxShadow: 4,
                borderRadius: 4,
            }} />

    )
}

export default MobileProductDetailImage;