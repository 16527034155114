import React, { useEffect, useRef, useState } from 'react'
import { getAllProducts, useLocation, useNavigate, useSelector } from "../../imports/generalImport";
import { Box, Container, SearchIcon, TextField, Button, Typography } from "../../imports/muiImport";
import { CircularProgress, InputAdornment } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

import MobileProductSearchResult from '../mobileComponents/mobileProductSearchResult/MobileProductSearchResult';
import { useDebounce } from '../../hooks/useDebounce';
import { Helmet } from 'react-helmet-async';


function MobileSearch() {
    const [inputValue, setInputValue] = useState('');
    const [searchResultData, setSearchResultData] = useState([]);
    const [productFound, setProductFound] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showProductList, setShowProductList] = useState(false);

    const bestSellerData = useSelector((state) => state.BestSeller.allData);
    const searchDebounce = useDebounce(inputValue);
    const inputRef = useRef(null);
    const resultBoxRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (inputValue.trim().length >= 1) {
            fetchUseProducts(searchDebounce);
        }
    }, [searchDebounce]);

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleClearInput = () => {
        setInputValue('');
    };

    return (
        <Container sx={{
            pt: 4,
        }}>

            <Helmet>
                <title>Product Search -99gift.in</title>
                <meta property="og:title" content="Product Search -99gift.in" />
            </Helmet>

            <Box sx={{
            }}>
                <TextField
                    id="outlined-basic"
                    variant="outlined"
                    fullWidth
                    placeholder='Search brand coupons and etc.'
                    value={inputValue}
                    ref={inputRef}
                    onChange={handleInputChange}
                    autoComplete='true'
                    autoFocus={location?.pathname === '/search' && true}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            borderRadius: 8,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderRadius: 8,
                        },
                        '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                            textIndent: 15,
                            fontSize: "17.5px",
                            fontWeight: 400,
                            color: "textPrimary.main"
                        },
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {inputValue ?
                                    <IconButton
                                        size="medium"
                                        style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}
                                        onClick={handleClearInput}
                                    >
                                        <ClearIcon />
                                    </IconButton>
                                    :
                                    <IconButton
                                        size="medium"
                                        style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                }
                            </InputAdornment>

                        ),
                    }}
                />
            </Box>


            {inputValue !== '' &&
                (
                    loading ?
                        (<Box sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "flex-start",
                            pt: 4,
                        }}>
                            <CircularProgress />
                        </Box>)
                        :
                        (productFound ?
                            (
                                <Box sx={{
                                    my: 4,
                                    minHeight: 180,
                                }}>
                                    <MobileProductSearchResult searchResult={searchResultData} />
                                </Box>
                            )
                            :
                            (
                                <Box sx={{
                                    my: 4,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}>
                                    <Typography variant="h6" color="textSecondary">
                                        No product found
                                    </Typography>
                                </Box>
                            )
                        )
                )
            }


        </Container >
    );


    async function fetchUseProducts(search) {
        if (search) {
            setLoading(true)
            setShowProductList(true); // Show the search result box when fetching products

            let response = await getAllProducts({
                search: search,
                filterBy: "title",
                selectedCategories: [],
                pagination: {
                    sortBy: "id",
                    descending: false,
                    page: 1,
                    rowsPerPage: 15,
                    rowsNumber: 0,
                },
            });

            if (response.status) {
                setLoading(false)

                if (response?.data?.data?.length) {
                    setSearchResultData(response?.data?.data);
                    setProductFound(true);
                } else {
                    setProductFound(false);
                }
            } else {
                console.error(response.error);
            }
        }
    }
}

export default MobileSearch