import React, { Fragment } from 'react';
import { Box, CircularProgress, Divider, List, ListItemButton, Typography } from '@mui/material';
import SingleSearchResult from './SingleSearchResult';
import resultNotFoundIcon from "../../../assets/vactor-images/result_not_found.png"
export default function SearchResult(props) {
    const { showProductList, resultBoxRef, searchResultData, resultNotFound, loading } = props;

    return (
        <Box
            ref={resultBoxRef} // Assign the reference to the search result box
            sx={{
                position: "absolute",
                display: showProductList ? "block" : "none", // Show or hide the search result box
                backgroundColor: "white.main",
                minHeight: {
                    sm: "240px",
                    xl: "160px"
                },
                maxHeight: {
                    sm: "240px",
                    xl: "280px"
                },
                width: "100%",
                zIndex: 99,
                borderRadius: {
                    xl: 1
                },
                boxShadow: {
                    xl: 3
                },
                overflow: 'auto',
                '::-webkit-scrollbar': {
                    display: 'none',
                },
                '-ms-overflow-style': 'none',  // IE and Edge
                'scrollbar-width': 'none',  // Firefox
            }}>
            <List>
                {/* {
                } */}

                {loading ?

                    (<Box sx={{
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center",
                        height: {
                            sm: "240px",
                            xl: "160px"
                        },
                    }}>
                        <CircularProgress />
                    </Box>)

                    :

                    (searchResultData?.map((item) => (
                        <Fragment key={item?.id}>
                            <SingleSearchResult item={item} />
                            <Divider />
                        </Fragment>
                    )))
                }



                {(resultNotFound && !loading) &&

                    <Box sx={{
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center",
                        height: {
                            sm: "240px",
                            xl: "160px"
                        },
                    }}>
                        <Box
                            component="img"
                            src={resultNotFoundIcon}
                            sx={{
                                height: {
                                    sm:30,
                                    lg: 40
                                },
                                width: {
                                    sm: 30,
                                    lg: 40
                                },
                            }}
                        />


                        <Typography
                            variant="body2"
                            sx={{
                                fontSize: {
                                    sm:".8rem",
                                    lg: "1.3rem",
                                },
                                lineHeight: 1,
                                fontWeight: 400,
                                color: "dark.main",
                            }}
                        >
                            No Result Found!
                        </Typography>
                    </Box>

                }

            </List>
        </Box>
    )






}