
import { Box, Typography } from '@mui/material'

const FooterReserved = props => {
    return (
        <Box sx={{ width: '100%', height: 40, bgcolor: 'secondary.main', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="subtitle2" color='primary.main'>
                © 99gift.in All rights reserved.
            </Typography>
        </Box>
    )
}

export default FooterReserved